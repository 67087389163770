import React, { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"

const Box = styled.div`
  flex: 1 0 25%;
`

const PlayersName = styled.h1`
  font-weight: 600;
  font-size: 1.5em;
  color: black;
  margin-top: 0;
  margin-bottom: 1em;
`

const PlayersSkin = styled.img`
  margin: 1em;
  width: 3.5em;
`

const StaffUser = ({ uuid }) => {
  const [playerName, setPlayerName] = useState()
  const [playerSkin, setPlayerSkin] = useState()

  useEffect(() => {
    axios.get(`https://mc-heads.net/minecraft/profile/${uuid}`).then(res => {
      if (res.data.name) {
        setPlayerName(res.data.name)
        setPlayerSkin(`https://mc-heads.net/body/${uuid}`)
      } else {
        setPlayerName("???")
        setPlayerSkin(`https://mc-heads.net/body/MHF_Steve`)
      }
    })
  }, [uuid])

  return (
    <Box>
      <PlayersSkin src={playerSkin} />
      <PlayersName>{playerName}</PlayersName>
    </Box>
  )
}

export default StaffUser
