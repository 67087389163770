import React from "react"
import styled from "styled-components"

const Box = styled.div`
  background-color: white;
  border-radius: 5px;
  margin-top: 2em;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.25);
`

const BoxHeader = styled.div`
  border-radius: 5px 5px 0 0;
  text-align: center;
  padding: 0.5em;
`

const BoxTitle = styled.h1`
  margin: 0;
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 500;
  color: white;
`

const BoxContents = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const StaffBox = ({ children, title, color }) => {
  return (
    <Box>
      <BoxHeader style={{ "background-color": color }}>
        <BoxTitle>{title}</BoxTitle>
      </BoxHeader>
      <BoxContents>{children}</BoxContents>
    </Box>
  )
}

export default StaffBox
