import React from "react"
import StaffBox from "../components/staff/box"
import StaffUser from "../components/staff/user"
import styled from "styled-components"
import staffList from "../components/_staffList"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Heading = styled.h1`
  display: inline-block;
  font-weight: 600;
`

const Apply = styled.a`
  border-radius: 4px;
  padding: 0.75em;
  font-size: 1.25em;
  font-weight: 500;
  display: flex;
  color: black;
  float: right;
  background-color: #ffaa00;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #e69900;
    cursor: pointer;
  }
  transition: background-color 0.25s ease;
  margin: 1em 0px;
`

const StaffPage = () => (
  <Layout page="staff">
    <SEO title="Staff" />
    <Heading>Staff List</Heading>
    <Apply>Apply for Staff</Apply>
    <StaffBox title="Owner" color="#AA0000">
      {staffList
        .filter(s => s.rank === "owner")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
    <StaffBox title="Sr. Admin" color="#FF5555">
      {staffList
        .filter(s => s.rank === "sradmin")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
    <StaffBox title="Admin" color="#FF5555">
      {staffList
        .filter(s => s.rank === "admin")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
    <StaffBox title="Sr. Mod" color="#AA00AA">
      {staffList
        .filter(s => s.rank === "srmod")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
    <StaffBox title="Mod" color="#AA00AA">
      {staffList
        .filter(s => s.rank === "mod")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
    <StaffBox title="T. Mod" color="#00AAAA">
      {staffList
        .filter(s => s.rank === "tmod")
        .map(s => (
          <StaffUser uuid={s.uuid} />
        ))}
    </StaffBox>
  </Layout>
)

export default StaffPage
