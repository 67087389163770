module.exports = [
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "owner",
  },
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "sradmin",
  },
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "admin",
  },
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "srmod",
  },
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "mod",
  },
  {
    uuid: "5dddb519-e659-4cf0-85f6-b0eacc018e1b",
    rank: "tmod",
  },
]
